<template>
  <div class="golden-sun">

    <img class="w100" style="height: 480px; display:block;object-fit: cover;" :src="options.banner">

    <div :class="active > 0 ? 'w100 tabs fixed' : 'w100 tabs' ">
      <div class="container">
        <!-- 导航区域 -->
        <ul class="navs">
          <li :class="{active: active==i}" v-for="(nav, i) in navs" :key="`navs` + i" @click="scrollTo(i)">
            {{nav.name}}
          </li>
        </ul>
      </div>
    </div>

    <!-- 内容区域 -->
    <div class="entry-content">
      <div class="content-item" id="1">
          <div class="company_profile" v-if="options.company_profile">
            <h3 class="font-rem-20">{{$t('page.goldenSun.company_profile')}}</h3>
            <div class="desc mt20 indent font-rem-12">{{options.company_profile[lang + 'desc']}}</div>
          </div>
      </div>

      <div class="content-item" id="2">
        <div class="vision_mission" v-if="options.vision_mission">
          <div class="left">
            <span>{{$t('page.goldenSun.vision_mission')}}</span>
          </div>
          <div class="right">
            <div class="desc indent font-rem-12">{{options.vision_mission[lang + 'desc']}}</div>
          </div>
        </div>
      </div>

      <div class="content-item" id="3">
        <div class="history" v-if="options.history">
          <div class="time-line-menu">
            <h3 class="font-rem-16 line">{{$t('page.goldenSun.development_history')}}</h3>
            <el-slider
                v-model="value2"
                @change="changeTime"
                :show-tooltip="false"
                :marks="timeLine"
                :step="step">
            </el-slider>
          </div>

          <div class="time-line">
            <div class="mod-con">
              <div class="history-main" :style="`left: -`+ left  +`px; width: `+ width + `px`">
                <div class="history-main-box" v-for="(list, i) in options.history.list" :year="list.title">
                  <div class="history-title">{{list[lang + 'title']}}</div>
                  <div class="li" v-for="item in list.item">
                    <div class="left"></div>
                    <div class="right">{{item[lang + 'content']}}</div>
                    <div class="both"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="content-item container"  id="4">
        <div class="business_culture" v-if="options.business_culture">
          <div class="left">
            <h3 class="font-rem-16">{{$t('page.goldenSun.business_culture')}}</h3>
            <div class="list" v-for="(list, i) in options.business_culture.list" :key="`business_culture` + i">
              <div class="font-rem-12">{{list[lang + 'title']}}</div>
              <div>{{list[lang + 'desc']}}</div>
            </div>
          </div>

          <div class="right">
            <!--<img class="w100" src="~@/assets/images/os/img_1.png">-->
          </div>
        </div>
      </div>

      <div class="content-item"  id="5">
        <div class="talent_concept" v-if="options.talent_concept">
          <h3 class="font-rem-20">{{$t('page.goldenSun.talent_concept')}}</h3>
          <div class="talent_concept_content">
            <div class="talent_concept_content_desc">
              <p>{{options.talent_concept[lang + 'desc']}}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="content-item container"  id="6">
        <div class="social_responsibility" v-if="options.social_responsibility">
          <h3 class="font-rem-20 line">{{$t('page.goldenSun.social_responsibility')}}</h3>
          <div class="desc mt20 indent font-rem-12">{{options.social_responsibility[lang + 'desc']}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getOption} from '@/api/os/admin';

export default {
  props: {},
  data() {
    return {
      active: 0, // 当前激活的导航索引
      optionName: 'golden_sun',
      options: {},
      value2: [0, 100],
      step: 1,
      left: 0,
      width: 0,
      // marks: {
      //   0: '0°C',
      //   8: '8°C',
      //   37: '37°C',
      //   // 50: {
      //   //   style: {
      //   //     color: '#1989FA'
      //   //   },
      //   //   label: this.$createElement('strong', '50%')
      //   // }
      // }
    }
  },
  mounted() {
    // 监听滚动事件
    window.addEventListener('scroll', this.onScroll, false);
    this.get()
  },
  destroy() {
    // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
    window.removeEventListener('scroll', this.onScroll)
  },
  computed: {
    lang() {
      if(this.$i18n.locale == 'zh-CN') {
        return ''
      }
      return 'en_';
    },
    navs() {
      return [
        {name: this.$t('page.goldenSun.company_profile')},
        {name: this.$t('page.goldenSun.vision_mission')},
        {name: this.$t('page.goldenSun.development_history')},
        {name: this.$t('page.goldenSun.business_culture')},
        {name: this.$t('page.goldenSun.talent_concept')},
        {name: this.$t('page.goldenSun.social_responsibility')},
      ]
    },
    timeLine() {
      if(this.options.history && this.options.history.list && this.options.history.list.length > 0) {
        let list = this.options.history.list.reverse();
        let index = list.length;
        let max = parseInt(100 / index);
        this.step = max
        this.width = parseInt((index + 1) * 384)
        console.log(this.step)
        let data = {}
        list.forEach((e, i) => {
          let num = i * max;
          if(num >= 100 ) num = 100
          data[num] = e.title
        })

        return data;
      }
    }
  },
  methods: {
    get() {
      this.loading = true
      getOption({
        name: this.optionName,
      }).then(res => {
        if(res.data.data) {
          this.options = res.data.data
        }
        this.loading = false
      })
    },
    changeTime(val) {
      this.left = parseInt(val / this.step) * 384
    },

    // 滚动监听器
    onScroll() {
      // 获取所有锚点元素
      const navContents = document.querySelectorAll('.entry-content .content-item')
      // 所有锚点元素的 offsetTop
      const offsetTopArr = []
      navContents.forEach(item => {
        offsetTopArr.push(item.offsetTop)
      })
      // 获取当前文档流的 scrollTop
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      // 定义当前点亮的导航下标
      let navIndex = 0
      for (let n = 0; n < offsetTopArr.length; n++) {
        // 如果 scrollTop 大于等于第n个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
        // 那么此时导航索引就应该是n了
        if (scrollTop >= offsetTopArr[n]) {
          navIndex = n
        }
      }
      this.active = navIndex
    },
    // 跳转到指定索引的元素
    scrollTo(index) {
      // 获取目标的 offsetTop
      // css选择器是从 1 开始计数，我们是从 0 开始，所以要 +1
      const targetOffsetTop = document.querySelector(`.entry-content .content-item:nth-child(${index + 1})`).offsetTop
      // 获取当前 offsetTop
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      // 定义一次跳 50 个像素，数字越大跳得越快，但是会有掉帧得感觉，步子迈大了会扯到蛋
      const STEP = 50
      // 判断是往下滑还是往上滑
      if (scrollTop > targetOffsetTop) {
        // 往上滑
        smoothUp()
      } else {
        // 往下滑
        smoothDown()
      }
      // 定义往下滑函数
      function smoothDown() {
        // 如果当前 scrollTop 小于 targetOffsetTop 说明视口还没滑到指定位置
        if (scrollTop < targetOffsetTop) {
          // 如果和目标相差距离大于等于 STEP 就跳 STEP
          // 否则直接跳到目标点，目标是为了防止跳过了。
          if (targetOffsetTop - scrollTop >= STEP) {
            scrollTop += STEP
          } else {
            scrollTop = targetOffsetTop
          }
          document.body.scrollTop = scrollTop
          document.documentElement.scrollTop = scrollTop
          // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
          requestAnimationFrame(smoothDown)
        }
      }
      // 定义往上滑函数
      function smoothUp() {
        if (scrollTop > targetOffsetTop) {
          if (scrollTop - targetOffsetTop >= STEP) {
            scrollTop -= STEP
          } else {
            scrollTop = targetOffsetTop
          }
          document.body.scrollTop = scrollTop
          document.documentElement.scrollTop = scrollTop
          requestAnimationFrame(smoothUp)
        }
      }
    }
  }
}
</script>

<style lang="less">
.golden-sun{
  .company_profile,
  .vision_mission{
    max-width: 1200px;
    margin: auto;
  }
}
/* 内容区的样式 */
.entry-content {
  /*background-color: white;*/
  /*width: 500px;*/
}
.entry-content div {
  //width: 100%;
  //height: 600px;
  //font-size: 36px;
  //padding: 20px;
  /*background-color: #7ec384;*/
}
.entry-content div:nth-child(2n) {
  //background-color: #847ec3;
}
/* 导航栏的样式 */
.tabs{
  background: #eeeeee;
  border-bottom: 1px solid #f4f4f4;
}
.navs {
  /*position: fixed;*/
  /*top: 80px;*/
  /*left: 700px;*/
  cursor: pointer;
  displey:-webkit-flex;
  display: flex;
  -webkit-flex-flow:row nowrap; //设置排列方式为横向排列，并且超出元素不换行
  flex-flow:row nowrap;
  overflow-x: auto;
  white-space:nowrap;
  max-width: 1200px;
  margin: auto;
  padding: 0 10px;
}
.navs li {
  margin-right: 60px;
  line-height: 50px;
  font-size: 16px;
  height: 50px;
  float: left;
}
/* 当导航被点亮后改变颜色 */
.navs .active{
  /*color: #847ec3;*/
  /*background-color: #e2e2e2;*/
  border-bottom: 2px solid #00923F;
}
.fixed {
  position: fixed;
  z-index: 99;
  top: 0px;
}
.content-item {
  width: 100%;
  overflow: hidden;
  margin-top: 50px;
  .company_profile{
    text-align: center;
    .desc{
      line-height: 2;
      text-align: left;
    }
  }
  .vision_mission{
    overflow: auto;
    padding-top: 30px;
    text-align: center;
    span{
      font-size: 2rem;
      font-weight: bold;
      line-height: 260px;
    }
    .left{
      width: calc(50% - 1px);
      border-right: 1px solid #696969;
      background-image: url('~@/assets/images/os/img_4.png');
      background-size: cover;
      background-position: center;
      span{
        opacity: 0;
      }
    }
    .right{
      width: 50%;
      float: left;
      height: 260px;
      flex: 1 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 2px;
      box-sizing: border-box;
      position: relative;
      overflow: hidden;
      .desc{
        box-sizing: border-box;
        margin: 0;
        min-width: 0;
        padding: 20px;
        border-radius: 2px;
        overflow: hidden;
        position: absolute;
        text-align: left;
        line-height: 2;
        z-index: 2;
      }
    }
  }

  .history{
    width: 100%;
    overflow: hidden;
    .el-slider__marks-text{
      color: #ffffff !important;
    }
    .time-line-menu{
      margin-top: 50px;
      padding: 20px;
      background-image: url('~@/assets/images/os/img_2.png');
      height: 384px;
      background-size: cover;
      background-position: center;
      h3{
        padding-top: 150px;
        color: #ffffff;
        margin: 20px auto 50px;
      }
    }
    .time-line{
      overflow: auto;
      height: 360px;
      .mod-con{
        position: relative;
        width: 1200px;
        margin: 0 auto;
      }
    }
    .history-main {
      width: 10000px;
      position: absolute;
      .history-main-box {
        width: 384px;
        float: left;
        margin-right: 25px;
        .history-title {
          font-size: 24px;
          padding-top: 40px;
          line-height: 36px;
          font-family: 'TencentSansw7';
          padding-bottom: 8px;
          border-bottom: 1px solid #D8D9DC;
          margin-bottom: 18px;
          color: #2A2E2E;
        }
        .li {
          line-height: 25px;
          margin-bottom: 18px;
          font-size: 14px;
          .left {
            float: left;
            width: 40px;
            padding-right: 10px;
            text-align: right;
          }
          .right {
            width: 320px;
            float: left;
          }
        }
      }
      .both {
        clear: both;
      }
    }
  }

  .business_culture{
    margin-top: 30px;
    height: 230px;
    .left{
      width: 50%;
      float: left;
      .list{
        margin-top: 20px;
      }
    }
    .right{
      width: 50%;
      float: left;
      height: 230px;
      background-image: url('~@/assets/images/os/img_1.png');
      background-size: cover;
      background-position: center;
    }
  }
  .talent_concept{
    margin: 50px auto;
    text-align: center;
    .talent_concept_content{
      background-image: url('~@/assets/images/os/img_3.png');
      padding-top: 170px;
      margin-top: 30px;
      background-size: cover;
      background-position: center;
    }
    .talent_concept_content_desc{
      font-size: 1.2rem;
      color: #ffffff;
      background: rgba(0, 146, 63, 0.67);
      line-height: 2;
      p{
        width: 80%;
        margin: auto;
        padding: 30px;
      }
    }
  }
  .social_responsibility{
    .desc{
      width: 80%;
      margin: 20px auto;
      line-height: 2;
    }
  }
}

@media screen and (max-width: 768px){
  .company_profile,
  .vision_mission{
    padding: 0 10px;
  }
  .vision_mission {
    .left{
      width: calc(30% - 1px) !important;
      span{
        font-size: 18px;
      }
    }
    .right{
      width: 70% !important;
    }
    .desc{
      font-size: 12px;
    }
  }
}

</style>